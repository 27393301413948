


import { IMenuItem } from "interfaces/menu-item.interface";
import { getTranslatedText } from "localization";
import {
  baseContactFormRoute,
  listContactFormRoute,
} from "../routes/contactForm.base.route";

export const contactFormDomainMenuItem: IMenuItem = {
  title: getTranslatedText("contactForm"),
  path: baseContactFormRoute(),
  iconName: "fa6-brands:wpforms",
};

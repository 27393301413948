import { subscriptionSlice } from "apps/main/domains/subscription/context";
import { contactFormSlice } from "apps/main/domains/contactForm/context";
import { webinarSlice } from "apps/main/domains/webinar/context";
import { policySlice } from "apps/main/domains/policy/context";
import { formSlice } from "apps/main/domains/form/context";
import { faqSlice } from "apps/main/domains/faq/context";
import { authSlice } from "apps/auth/context/slices/auth";
import { blogSlice } from "apps/main/domains/blog/context";
import { dashboardSlice } from "apps/main/domains/dashboard/context";
import { configureStore } from "@reduxjs/toolkit";
import { commonApi } from "./commonApi";

export const store = configureStore({
  devTools: true,
  reducer: {
    auth: authSlice,
    blog: blogSlice,
    dashboard: dashboardSlice,
    faq: faqSlice,
    form: formSlice,
    policy: policySlice,
    webinar: webinarSlice,
    contactForm: contactFormSlice,
    subscription: subscriptionSlice,
    [commonApi.reducerPath]: commonApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(commonApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

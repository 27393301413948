

import { commonRoutes } from "routes/route.common";

export const contactFormDomainRoutes = {
  base: "contactForm",
  subdomains: {
    contactForm: {
      base: "contactForms",
    },
    // add subdomain route names here
  },
};

// ContactForm routes
export const baseContactFormRoute = (): string => `/${contactFormDomainRoutes.base}/`;
export const listContactFormRoute = (): string =>
  `/${contactFormDomainRoutes.base}/${contactFormDomainRoutes.subdomains.contactForm.base}`;
export const viewContactFormRoute = (id: string): string =>
  `/${contactFormDomainRoutes.base}/${contactFormDomainRoutes.subdomains.contactForm.base}/${id}`;
export const createContactFormRoute = (): string =>
  `/${contactFormDomainRoutes.base}/${contactFormDomainRoutes.subdomains.contactForm.base}/${commonRoutes.create}`;
export const updateContactFormRoute = (id: string): string =>
  `/${contactFormDomainRoutes.base}/${contactFormDomainRoutes.subdomains.contactForm.base}/${id}/${commonRoutes.update}`;


import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  useCreatePolicyMutation,
} from "../context/policy.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { policyActions } from "../../../context";
import { ICreatePolicyInput, PolicyDomain, PolicyTarget, PolicyType } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables, Language } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { validateCreatePolicyInput } from "../validations/create.validation";
import { t } from "i18next";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import ReactQuill from "react-quill";

const Create = () => {
  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [
    createPolicy,
    {
      data: createPolicyData,
      isLoading: createPolicyIsLoading,
      error: createPolicyError,
    },
  ] = useCreatePolicyMutation();

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } =
    useFormik({
      initialValues: {
        input: {
          title: "",
          content: "",
          domain: PolicyDomain.main,
          type: PolicyType.cookie,
          target: PolicyTarget.general,
          language: currentLanguage,
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreatePolicyInput,
      onSubmit: (values: IGraphqlVariables<ICreatePolicyInput>) => {
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values.input,
          });
        createPolicy({
          ...values,
          input: transformedValues
        });
      },
    });

  // useEffects.success
  useEffect(() => {
    if (createPolicyData) {
      console.log(createPolicyData, "data");
      resetForm();
      enqueueSnackbar(t("main.policy.policy.createdSuccessfully"), {
        variant: "success",
      });
    }
  }, [createPolicyData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      createPolicyError as IBaseError,
      currentLanguage
    );
  }, [createPolicyError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(policyActions.setTitle(t("main.policy.policy.create")));
    dispatch(policyActions.setBackButton(true));
    dispatch(policyActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>

        <TextField
          label={t("main.policy.policy.title")}
          variant="outlined"
          fullWidth
          name="input.title"
          value={(values as any).input.title}
          onChange={(e) => setFieldValue("input.title", e.target.value)}
          onBlur={handleBlur}
          error={!!(errors as any).input?.title}
          helperText={(errors as any).input?.title}
        />

        <ReactQuill
          theme="snow"
          value={(values as any).input.content}
          onChange={(e) => setFieldValue("input.content", e)}
          onBlur={() => handleBlur("input.content")}
          placeholder={t("main.policy.policy.content")}
          scrollingContainer={'.ql-editor'}
          style={{ height: 300, marginBottom: 40, paddingBottom: 10 }}
        />


        <Autocomplete
          options={Object.values(PolicyDomain)}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("main.policy.policy.domain")}
              variant="outlined"
              error={!!(errors as any).input?.domain}
              helperText={(errors as any).input?.domain}
            />
          )}
          value={(values as any).input.domain}
          onChange={(e, value) => setFieldValue("input.domain", value)}
        />

        <Autocomplete
          options={Object.values(PolicyType)}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("main.policy.policy.type")}
              variant="outlined"
              error={!!(errors as any).input?.type}
              helperText={(errors as any).input?.type}
            />
          )}
          value={(values as any).input.type}
          onChange={(e, value) => setFieldValue("input.type", value)}
        />

        <Autocomplete
          options={Object.values(PolicyTarget)}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("main.policy.policy.target")}
              variant="outlined"
              error={!!(errors as any).input?.target}
              helperText={(errors as any).input?.target}
            />
          )}
          value={(values as any).input.target}
          onChange={(e, value) => setFieldValue("input.target", value)}
        />

        <Autocomplete
          id="language"
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={Object.values(Language)}
          renderInput={(params) => <TextField {...params} label={t("main.policy.policy.language")} />}
          value={(values as any).input.language}
          onChange={(e, value) => {
            setFieldValue("input.language", value);
          }}
          onBlur={() => handleBlur("input.language")}
        />

        {/* <TextField
          label={t("main.policy.policy.versionCount")}
          variant="outlined"
          fullWidth
          name="input.versionCount"
          type="number"
          inputProps={{ step: "0.01" }}
          value={(values as any).input.versionCount}
          onChange={(e) => setFieldValue("input.versionCount", parseFloat(e.target.value))}
          onBlur={handleBlur}
          error={!!(errors as any).input?.versionCount}
          helperText={(errors as any).input?.versionCount}
        /> */}
        {/* Question : version count'ı create ve update'de kullanmayacağım sanırım? Otomatik mi belirleniyor?  */}



        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={createPolicyIsLoading || !(values as any).input.title || !(values as any).input.content || !(values as any).input.domain || !(values as any).input.type || !(values as any).input.target || !(values as any).input.language}
        >
          {createPolicyIsLoading ? (
            <CircularProgress size="1rem" sx={{ mr: 2 }} />
          ) : (
            ""
          )}
          Create
        </Button>
      </Stack>
    </Grid>
  );
};

export default Create;

import { AuthRouter } from 'apps/auth/routes/AuthRouter';
import { MainRouter } from 'apps/main/routes/MainRouter';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'context';
import { setUser } from 'apps/auth/context';
import { LoadingPage } from 'components';
import { delay } from 'utils';


function Router() {
  const dispatch = useAppDispatch();
  const { type } = useAppSelector(state => state.auth);
  const [ready, setReady] = useState(false);


  useEffect(() => {
    const user = localStorage.getItem("auth");  // TODO: store string to a variable
    dispatch(setUser(JSON.parse(user ?? "{}")));
    delay(500).then(() => setReady(true));

  }, [dispatch]);



  const renderUserTypeRouter = () => {
    if (!ready) {
      return <Route path="*" element={<LoadingPage />} />
    }
    switch (type) {
      case "cocrm": // TODO: store string to a variable
        return MainRouter();
      default:
        return AuthRouter()
    }
  }


  return (
    <BrowserRouter>
      <Routes>
        {renderUserTypeRouter()}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;

import contactFormLocale from "../subdomains/contactForm/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  contactForm: {
    ...contactFormLocale,
  },

  // TODO: import subdomain locales
};

export default locale;

import {
  IBlogsInput,
  IBlogsRequest,
  IBlogsResponse,
  IBlogsResult,
  blogQuery,
  blogsQuery,
  IBlogInput,
  IBlogRequest,
  IBlogResponse,
  IBlogResult,
  ICreateBlogInput,
  ICreateBlogRequest,
  ICreateBlogResponse,
  ICreateBlogResult,
  createBlogQuery,
  IUpdateBlogInput,
  IUpdateBlogRequest,
  IUpdateBlogResponse,
  IUpdateBlogResult,
  updateBlogQuery,
  IUpdateBlogFilterInput,
  deleteBlogQuery,
  IDeleteBlogInput,
  IDeleteBlogRequest,
  IDeleteBlogResponse,
  IDeleteBlogResult,
  blogImagePresignedUrlQuery,
  IBlogImagePresignedUrlResult,
  IBlogImagePresignedUrlRequest,
  IBlogImagePresignedUrlResponse,

} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { blogTags } from "./blog.tags";
import { IGraphqlVariables } from "corede-common";

export const blogApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    blogs: builder.query<
      IBlogsResult,
      IGraphqlVariables<IBlogsInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBlogsRequest,
        IBlogsResponse,
        IBlogsResult,
        IBlogsInput
      >({
        query: blogsQuery,
        providesTags: [blogTags.blogs],
      })
    ),

    blog: builder.query<
      IBlogResult,
      IGraphqlVariables<IBlogInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBlogRequest,
        IBlogResponse,
        IBlogResult,
        IBlogInput
      >({
        query: blogQuery,
        providesTags: [blogTags.blog],
      })
    ),

    blogImagePresignedUrl: builder.query<
      IBlogImagePresignedUrlResult,
      IGraphqlVariables<IBlogImagePresignedUrlRequest>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBlogImagePresignedUrlRequest,
        IBlogImagePresignedUrlResponse,
        IBlogImagePresignedUrlResult
      >({
        query: blogImagePresignedUrlQuery,
      })
    ),


    // mutation
    createBlog: builder.mutation<
      ICreateBlogResult,
      IGraphqlVariables<ICreateBlogInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICreateBlogRequest,
        ICreateBlogResponse,
        ICreateBlogResult,
        ICreateBlogInput
      >({
        query: createBlogQuery,
        invalidatesTags: [blogTags.blogs],
      })
    ),

    updateBlog: builder.mutation<
      IUpdateBlogResult,
      IGraphqlVariables<
        IUpdateBlogInput,
        IUpdateBlogFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUpdateBlogRequest,
        IUpdateBlogResponse,
        IUpdateBlogResult,
        IUpdateBlogInput,
        IUpdateBlogFilterInput
      >({
        query: updateBlogQuery,
        invalidatesTags: [
          blogTags.blogs,
          blogTags.blog,
        ],
      })
    ),

    deleteBlog: builder.mutation<
      IDeleteBlogResult,
      IGraphqlVariables<IDeleteBlogInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDeleteBlogRequest,
        IDeleteBlogResponse,
        IDeleteBlogResult,
        IDeleteBlogInput
      >({
        query: deleteBlogQuery,
        invalidatesTags: [
          blogTags.blogs,
          blogTags.blog,
        ],
      })
    ),
  }),
});

export const {
  useBlogsQuery,
  useLazyBlogsQuery,

  useBlogQuery,
  useLazyBlogQuery,

  useBlogImagePresignedUrlQuery,
  useLazyBlogImagePresignedUrlQuery,

  useCreateBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
} = blogApi;

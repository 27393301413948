import axios from "axios";
import Compressor from "compressorjs"
import { getAccessToken } from "./getUserInfo";



export const dmsConfig = (data: string) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/graphql`,
    headers: {
        'Authorization': `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data
});



export const findImageContentType = (file: { type: string; }) => {

    if (file?.type === "image/png") return "pngImage"
    return "jpegImage"

}

export const compressImage = (file: File | Blob, quality?: any) => {

    return new Promise((resolve, reject) => {

        new Compressor(
            file, {
            quality: quality ?? 0.6,
            success(result) {
                resolve(result)
            },
            error(e) {
                reject(e)
            },
        })

    })

}


export const saveImageAndGetUrl = async (
    {
        presignUrl,
        file,
    }: {
        presignUrl: string,
        file: File | Blob | any,
    }
) => {

    try {
        const config = {
            method: 'PUT',
            maxBodyLength: Infinity,
            url: presignUrl,
            headers: {
                'x-amz-acl': 'public-read',
                'Content-Type': 'image/*'
            },
            data: file
        };

        const response = await axios(config)
        return response

    } catch (error) {
        return error
    }


}
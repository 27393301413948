import blogLocale from "../domains/blog/locales/en";
import subscriptionLocale from "../domains/subscription/locales/en";
import contactFormLocale from "../domains/contactForm/locales/en";
import webinarLocale from "../domains/webinar/locales/en";
import policyLocale from "../domains/policy/locales/en";
import formLocale from "../domains/form/locales/en";
import faqLocale from "../domains/faq/locales/en";
import dashboardLocale from "../domains/dashboard/locales/en";

const locale = {
  dashboard: {
    ...dashboardLocale,
  },
  blog: {
    ...blogLocale,
  },
  faq: {
    ...faqLocale,
  },
  form: {
    ...formLocale,
  },
  policy: {
    ...policyLocale,
  },
  webinar: {
    ...webinarLocale,
  },
  contactForm: {
    ...contactFormLocale,
  },
  subscription: {
    ...subscriptionLocale,
  },
};

export default locale;

import { ContactFormRouter } from "../subdomains/contactForm/routes/ContactFormRouter";
import { ContactFormDomainLayout } from "../layouts/ContactFormDomainLayout";
import { contactFormDomainRoutes } from "./contactForm.base.route";
import { Route, Routes } from "react-router-dom";

export const ContactFormDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ContactFormDomainLayout />}>
        <Route index element={<ContactFormRouter />} />
        <Route
          path={`/${contactFormDomainRoutes.subdomains.contactForm.base}/*`}
          element={<ContactFormRouter />}
        />
      </Route>
    </Routes>
  );
};

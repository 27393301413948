import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  useUpdatePolicyMutation,
  usePolicyQuery,
} from "../context/policy.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { policyActions } from "../../../context";
import { IUpdatePolicyInput,} from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdatePolicyInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listPolicyRoute } from "../../../routes/policy.base.route";
import ReactQuill from "react-quill";

const Update = () => {

  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listPolicyRoute());
  }

  // queries
  const {
    data: policyData,
    isLoading: policyIsLoading,
    error: policyError,
  } = usePolicyQuery({ input: { _id: id! } });

  // mutations
  const [
    updatePolicy,
    {
      data: updatePolicyData,
      isLoading: updatePolicyIsLoading,
      error: updatePolicyError,
    },
  ] = useUpdatePolicyMutation();

  // constants
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        title: "",
        content: "",
      },
    },
    enableReinitialize: true,
    validationSchema: validateUpdatePolicyInput,
    onSubmit: (values: IGraphqlVariables<IUpdatePolicyInput>) => {
      updatePolicy({
        filter: { _id: id! },
        input: values.input,
      });
    },
  });

  // useEffects.success
  useEffect(() => {
    console.log(policyData);
    if (policyData) {
      setValues({
        input: {
          title: policyData.title,
          content: policyData.content,
        },
      });
    }
  }, [policyData, setValues]);

  useEffect(() => {
    if (updatePolicyData) {
      resetForm();
      enqueueSnackbar(t("main.policy.policy.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updatePolicyData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updatePolicyError as IBaseError,
      currentLanguage
    );
  }, [updatePolicyError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      policyError as IBaseError,
      currentLanguage
    );
  }, [policyError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(policyActions.setTitle(t("main.policy.policy.updateWithoutVersion")));
    dispatch(policyActions.setBackButton(true));
    dispatch(policyActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {policyIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>

            <TextField
              label={t("main.policy.policy.title")}
              variant="outlined"
              fullWidth
              name="input.title"
              value={(values as any).input.title}
              onChange={(e) => setFieldValue("input.title", e.target.value)}
              onBlur={handleBlur}
              error={!!(errors as any).input?.title}
              helperText={(errors as any).input?.title}
            />

            <ReactQuill
              theme="snow"
              value={(values as any).input.content}
              onChange={(e) => setFieldValue("input.content", e)}
              onBlur={() => handleBlur("input.content")}
              placeholder={t("main.policy.policy.content")}
              scrollingContainer={'.ql-editor'}
              style={{ height: 300, marginBottom: 40, paddingBottom: 10 }}
            />


            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updatePolicyIsLoading}
            >
              {updatePolicyIsLoading ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default Update;

import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  useCreateFaqCategoryMutation,
  useFaqCategoriesQuery,
} from "../context/faqCategory.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { faqActions } from "../../../context";
import { ICreateFaqCategoryInput } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { validateCreateFaqCategoryInput } from "../validations/create.validation";
import { t } from "i18next";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";

const Create = () => {
  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();

  // queries

  const {
    data: faqCategoriesData,
    isLoading: faqCategoriesIsLoading,
    error: faqCategoriesError,
  } = useFaqCategoriesQuery({});

  // mutations
  const [
    createFaqCategory,
    {
      data: createFaqCategoryData,
      isLoading: createFaqCategoryIsLoading,
      error: createFaqCategoryError,
    },
  ] = useCreateFaqCategoryMutation();

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } =
    useFormik({
      initialValues: {
        input: {
          name: "",
          icon: "",
          parentCategoryId: "",
          nameTranslation: undefined,
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateFaqCategoryInput,
      onSubmit: (values: IGraphqlVariables<ICreateFaqCategoryInput>) => {
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values.input,
          });
        createFaqCategory({
          ...values,
          input: transformedValues,
        });
      },
    });

  // useEffects.success
  useEffect(() => {
    if (createFaqCategoryData) {
      console.log(createFaqCategoryData, "data");
      resetForm();
      enqueueSnackbar(t("main.faq.faqCategory.createdSuccessfully"), {
        variant: "success",
      });
    }
  }, [createFaqCategoryData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      createFaqCategoryError as IBaseError,
      currentLanguage
    );
  }, [createFaqCategoryError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      faqCategoriesError as IBaseError,
      currentLanguage
    );
  }, [faqCategoriesError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(faqActions.setTitle(t("main.faq.faqCategory.create")));
    dispatch(faqActions.setBackButton(true));
    dispatch(faqActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>

        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          value={(values as any).input.name}
          onChange={(e) => setFieldValue("input.name", e.target.value)}
          onBlur={() => handleBlur("input.name")}
          error={Boolean((errors as any).input?.name)}
          helperText={(errors as any).input?.name}
        />
        <TextField
          fullWidth
          label="Name - English"
          variant="outlined"
          value={(values as any).input.nameTranslation?.en}
          onChange={(e) => setFieldValue("input.nameTranslation.en", e.target.value)}
          onBlur={() => handleBlur("input.nameTranslation.en")}
          error={Boolean((errors as any).input?.nameTranslation?.en)}
          helperText={(errors as any).input?.nameTranslation?.en}
        />
        <TextField
          fullWidth
          label="Name - Turkish"
          variant="outlined"
          value={(values as any).input.nameTranslation?.tr}
          onChange={(e) => setFieldValue("input.nameTranslation.tr", e.target.value)}
          onBlur={() => handleBlur("input.nameTranslation.tr")}
          error={Boolean((errors as any).input?.nameTranslation?.tr)}
          helperText={(errors as any).input?.nameTranslation?.tr}
        />
        <TextField
          fullWidth
          label="Icon"
          variant="outlined"
          value={(values as any).input.icon}
          onChange={(e) => setFieldValue("input.icon", e.target.value)}
          onBlur={() => handleBlur("input.icon")}
          error={Boolean((errors as any).input?.icon)}
          helperText={(errors as any).input?.icon}
        />
        <Autocomplete
          id="parentCategoryId"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.name}
          options={faqCategoriesData?.data || []}
          loading={faqCategoriesIsLoading}
          renderInput={(params) => <TextField {...params} label={t("main.blog.blogCategory.parentCategory")} />}
          value={faqCategoriesData?.data?.find((category) => category._id === (values as any).input.parentCategoryId) || null}
          onChange={(e, value) => {
            setFieldValue("input.parentCategoryId", value?._id);
          }}
          onBlur={() => handleBlur("input.parentCategoryId")}
        />


        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={createFaqCategoryIsLoading || !(values as any).input.name}
        >
          {createFaqCategoryIsLoading ? (
            <CircularProgress size="1rem" sx={{ mr: 2 }} />
          ) : (
            ""
          )}
          Create
        </Button>
      </Stack>
    </Grid>
  );
};

export default Create;
